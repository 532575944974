<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>赛务管理</el-breadcrumb-item>
            <el-breadcrumb-item>个人赛-签到抽签</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <div class="title">
            {{ queryForm.matchTypeName }} - {{ queryForm.groupName }} 签到数据
        </div>
        <div class="title">
            总人数
            <span style="color: blue">{{ dataList.length }}</span>
            ；已签到：
            <span style="color: green">{{ isSignArray.length }}</span>
            ；未签到：
            <span style="color: red">{{ unSignArray.length }}</span>
        </div>
        <!-- 签到筛选 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span>签到筛选</span>
                </el-col>
            </el-row>
            <el-form :model="queryForm" label-width="80px" ref="queryFormRef">
                <el-row :gutter="50">
                    <el-col :span="8">
                        <el-form-item label="学校名称" prop="schoolName">
                            <el-input
                                v-model="queryForm.schoolName"
                                placeholder="学校/机构/幼儿园名称"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="选手姓名" prop="userName">
                            <el-input
                                v-model="queryForm.userName"
                                placeholder="请输入选手姓名"
                                @change="getUserNameList()"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="参赛账号" prop="phone">
                            <el-input
                                clear
                                v-model="queryForm.phone"
                                placeholder="请输入参赛手机号"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="状态" prop="signIn">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                clearable
                                v-model="queryForm.signIn"
                            >
                                <el-option
                                    v-for="item in dict_state"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20" type="flex" justify="end">
                    <el-col :span="2">
                        <el-button
                            type="primary"
                            style="width: 100%"
                            @click="getDataList"
                            >查询</el-button
                        >
                    </el-col>
                    <el-col :span="2">
                        <el-button
                            type="primary"
                            style="width: 100%"
                            @click="reset"
                            >重置</el-button
                        >
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <!-- 签到列表 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col :span="12">
                    <div class="list_title">签到列表</div>
                </el-col>
                <el-col :span="12">
                    <div class="div_algin_right">
                        <el-button type="warning" @click="resetNumberPlate"
                            >重置号码牌</el-button
                        >
                        <el-button type="primary" @click="generatingNumberPlate"
                            >一键生成号码牌</el-button
                        >
                        <el-button
                            type="success"
                            @click="startSignIn"
                            :disabled="notUploadMatchNumData !== ''"
                            >开始签到</el-button
                        >
                        <el-button type="primary" @click="downloadExcel"
                            >下载裁判席用表</el-button
                        >
                    </div>
                </el-col>
            </el-row>
            <el-row v-if="this.notUploadMatchNumData !== ''">
                <span style="color: red"
                    >检测到有未上传的签到数据,请重新上传</span
                >
                <el-button
                    size="medium"
                    type="danger"
                    @click="uploadSignInData()"
                    style="margin-left: 15px"
                    >重新上传</el-button
                >
            </el-row>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="dataList"
                border
            >
                <el-table-column type="index"></el-table-column>
                <el-table-column label="姓名" prop="userName">
                    <template slot-scope="scope">
                        <span type="text" size="small"
                            >{{ scope.row.userName }}
                            {{
                                scope.row.countryReserve === true
                                    ? "(保留)"
                                    : ""
                            }}</span
                        >
                    </template>
                </el-table-column>
                <el-table-column label="比赛号码" prop="competitionNumber">
                    <template slot-scope="scope">
                        <el-tag type="danger">{{
                            scope.row.competitionNumber
                                ? scope.row.competitionNumber
                                : "-"
                        }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="手机号" prop="phone"></el-table-column>
                <el-table-column
                    label="性别"
                    prop="sex"
                    :formatter="genderFormatter"
                ></el-table-column>
                <el-table-column
                    label="学校"
                    prop="schoolName"
                ></el-table-column>
                <el-table-column
                    label="年级"
                    prop="grade"
                    :formatter="gradeFormatter"
                ></el-table-column>
                <el-table-column
                    v-if="
                        queryForm.raceSchedule === '5' ||
                        queryForm.raceSchedule === '6' ||
                        queryForm.raceSchedule === '4'
                    "
                    label="系统年级"
                    prop="userInfoGrade"
                    :formatter="userInfoGradeFormatter"
                ></el-table-column>
                <el-table-column label="签到二维码">
                    <template slot-scope="scope">
                        <el-tag
                            type="success"
                            v-if="scope.row.qrCodeUrl !== ''"
                            @click="showQr(scope.row.qrCodeUrl)"
                            >有</el-tag
                        >
                        <el-tag type="error" v-else>无</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="签到状态">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.signIn"
                            >已签到</el-tag
                        >
                        <el-tag type="warning" v-else>未签到</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="签到修改" prop="signIn">
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.signIn"
                            :active-value="true"
                            @change="signInChange(scope.row)"
                        ></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="180px">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            size="small"
                            @click="showEditDialog(scope.row)"
                            :disabled="notUploadMatchNumData !== ''"
                            >信息修改</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            size="small"
                            @click="changeGradeDig(scope.row)"
                            >修改年级</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <!-- 签到中的dialog -->
        <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            width="30%"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
            :show-close="false"
        >
            <div>
                请在签到页面完成签到，确认签到完毕后再点击签到结束，并下载裁判席用表
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="danger" @click="signInEnd()"
                    >签到结束</el-button
                >
            </span>
        </el-dialog>
        <!-- 是否继续签到的dialog -->
        <el-dialog
            title="提示"
            :visible.sync="continueDialogVisible"
            width="30%"
        >
            <div>
                检测到已有签到数据, 是否继续?<br />继续签到：将保留已签到数据，继续签到.
                <!-- 检测到已有签到数据, 是否继续?<br />继续签到：将保留已签到数据，继续签到。<br />重置签到：将清空签到数据，可以重新签到 -->
            </div>
            <span slot="footer" class="dialog-footer">
                <!-- <el-button type="warning" @click="resetSignIn()">重置签到</el-button> -->
                <el-button type="primary" @click="goToSignInDetail()"
                    >继续签到</el-button
                >
            </span>
        </el-dialog>
        <!-- 显示签到二维码的dialog -->
        <el-dialog
            title="签到二维码"
            :visible.sync="showQrDialogVisible"
            width="30%"
        >
            <img :src="qrImgUrl" style="width: 100%" />
        </el-dialog>
        <!-- 修改的dialog -->
        <el-dialog title="修改" :visible.sync="editDialogVisible" width="30%">
            <el-form :model="uploadMatchNumData">
                <el-form-item label="姓名:">
                    <div>{{ uploadMatchNumData.userName }}</div>
                </el-form-item>
                <el-form-item label="手机:">
                    <div>{{ uploadMatchNumData.phone }}</div>
                </el-form-item>
                <el-form-item label="学校:">
                    <div>{{ uploadMatchNumData.schoolName }}</div>
                </el-form-item>
                <el-form-item label="年级:">
                    <div>{{ uploadMatchNumData.grade }}</div>
                </el-form-item>
                <el-form-item label="签到状态:">
                    <el-switch
                        v-model="uploadMatchNumData.signIn"
                        :active-value="true"
                    ></el-switch>
                </el-form-item>
                <el-form-item label="比赛号码:">
                    <el-input-number
                        v-model="uploadMatchNumData.competitionNumber"
                    ></el-input-number>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="editSignInData"
                    >确 认</el-button
                >
            </span>
        </el-dialog>
          <!-- 修改年级 -->
          <el-dialog
            title="修改年级和组别"
            :visible.sync="showChangeGradeDialogVisible"
            @close="showChangeGradeDialogClose"
            width="30%"
        >
            <el-form
                :model="changeGradeForm"
                label-width="80px"
                ref="changeGradeFormRef"
                :rules="changeGradeFormRules"
            >
                <el-form-item label="姓名" prop="userName">
                    <el-input
                        v-model="changeGradeForm.userName"
                        placeholder="请输入"
                        disabled
                    ></el-input>
                </el-form-item>
                <el-form-item label="年级" prop="grade">
                    <el-select
                        v-model="changeGradeForm.grade"
                        placeholder="请选择"
                        @change="gradeChangeClicked"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in dict_grade"
                            :key="item.dictCode"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showChangeGradeDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="editGradeScore"
                    >修 改</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {
  queryTbEnrollmentList, checkSetPromotionNum, createCompetitionNumberList, resetCompetitionNumberList, citySignIn
  , updateLevel
} from '@/http/api'
export default {
  data () {
    return {
      changeGradeForm: {
        enrollmentLevel: '',
        grade: null
      },
      changeGradeFormRules: {
        grade: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      showChangeGradeDialogVisible: false,
      e_sex: this.$userInfo.getDataList(this, 'e_sex').then(value => { this.e_sex = value }),
      queryForm: JSON.parse(this.$route.query.division),
      total: 0,
      dict_state: [
        {
          dictCode: '1',
          dictLabel: '已签到',
          dictValue: true
        },
        {
          dictCode: '2',
          dictLabel: '未签到',
          dictValue: false
        }
      ],
      dataList: [],
      isSignArray: [],
      unSignArray: [],
      dialogVisible: false,
      dict_grade: this.$userInfo.getGradeData(),
      continueDialogVisible: false,
      routeData: '',
      showQrDialogVisible: false,
      qrImgUrl: '',
      uploadMatchNumData: {},
      editDialogVisible: false,
      notUploadMatchNumData: '',
      signInEnable: false
    }
  },
  created () {
    this.checkSignInEnable()
    this.getDataList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    async getDataList () {
      this.checkNotUploadMatchNumData() // 先检查是否有未上传的数据
      this.queryForm.pageNum = 1
      this.queryForm.pageSize = 1000000
      queryTbEnrollmentList(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.isSignArray = []
        this.unSignArray = []
        for (let index = 0; index < this.dataList.length; index++) {
          const element = this.dataList[index]
          if (element.signIn) {
            this.isSignArray.push(element)
          } else {
            this.unSignArray.push(element)
          }
        }
      }).catch((err) => {
        console.log('err', err)
      })
    },
    getUserNameList (value) {
      // 模糊查询
      var newList = this.dataList.filter(item => item.indexOf(value) > -1)
      this.dataList = newList
    },
    // 重置号码牌
    resetNumberPlate () {
      this.$confirm('如果重置号码牌 ，将会导致<span style="color: red; font-weight: bold;">比赛号码重新排序</span>,请确保裁判席未使用该套号码牌，如不确认，请联系裁判席核实，重置后需<span style="color: red; font-weight: bold;">重新打印裁判席用表</span>', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(async () => {
        resetCompetitionNumberList(this.queryForm).then((res) => {
          this.getDataList()
        }).catch((err) => {
          console.log('err', err)
        })
      }).catch(() => {
      })
    },
    // 一键生成号码牌
    generatingNumberPlate () {
      this.$confirm('是否确认生成号码牌?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        createCompetitionNumberList(this.queryForm).then((res) => {
          this.getDataList()
        }).catch((err) => {
          console.log('err', err)
        })
      }).catch(() => {
      })
    },
    startSignIn () {
      if (this.signInEnable) {
        return this.$message.warning('比赛已开始，不可修改签到')
      }
      // 判断是否已有签到数据，继续签到还是从头签到
      if (this.dataList.length > 0) {
        // var isSignIn = false
        // this.dataList.forEach(element => {
        //   if (element.competitionNumber !== '') {
        //     isSignIn = true
        //   }
        // })
        // if (isSignIn) {
        //   this.continueDialogVisible = true
        // } else {
        this.goToSignInDetail()
        // }
      } else {
        this.$message.warning('没有要签到的选手')
      }
    },
    downloadExcel () {
      var downLoadUrl = this.$env.baseIP + 'enrollment/downRefereeExcel'
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, this.queryForm)
      // require.ensure([], () => {
      //   // 直接将当前页面的数据导出成excel表
      //   // eslint-disable-next-line camelcase
      //   const { export_json_to_excel } = require('../../../../../../assets/Export2Excel.js')
      //   const tHeader = ['序号', '姓名', '学校', 'Round 1', 'Round 2', 'Round 3', 'Round 4', 'Round 5', 'Round 6', 'Round 7', 'Round 8', 'Round 9', 'Round 10']
      //   const filterVal = ['matchNum', 'realName', 'schoolName']
      //   const list = this.dataList
      //   const data = this.formatJson(filterVal, list)
      //   console.log('asdasd', list, data)
      //   export_json_to_excel(tHeader, data, (this.divisionName === undefined ? '' : this.divisionName + '-') + this.matchTypeName + '-' + (this.groupName === undefined ? '' : this.groupName + '-') + '轮次记录表')
      // })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    signInEnd () {
      this.$confirm('此操作将结束签到, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 结果排序
        // var compare = function (prop) {
        //   return function (obj1, obj2) {
        //     var a = obj1[prop]
        //     var b = obj2[prop]
        //     if (a === '') {
        //       return 1
        //     }
        //     if (b === '') {
        //       return -1
        //     }
        //     var numA = Number(a)
        //     var numB = Number(b)
        //     if (numA === numB) {
        //       return 0
        //     } else {
        //       // 升序
        //       return numA < numB ? -1 : 1
        //     }
        //   }
        // }
        // // 拿到签到页面保存的数据
        // var temp = JSON.parse(window.localStorage.getItem(this.queryForm.raceSchedule + this.queryForm.districtId + this.queryForm.level + 'signInData'))
        // if (temp === null) return // 没签到数据则返回
        // this.dataList = temp
        // this.dataList.sort(compare('matchNum'))
        // this.checkNotUploadMatchNumData() // 检查是否有未上传的数据
        // // 清空缓存
        // window.localStorage.removeItem(this.queryForm.raceSchedule + this.queryForm.districtId + this.queryForm.level + 'numPool')
        // window.localStorage.removeItem(this.queryForm.raceSchedule + this.queryForm.districtId + this.queryForm.level + 'signInData')
        this.dialogVisible = false
        this.getDataList()
      }).catch(() => {
      })
    },
    gradeFormatter (data) {
      var temp = ''
      this.dict_grade.forEach(element => {
        if (element.dictValue === data.grade) {
          temp = element.dictLabel
        }
      })
      return temp
    },
    userInfoGradeFormatter (data) {
      var temp = ''
      this.dict_grade.forEach(element => {
        if (element.dictValue === data.userInfoGrade) {
          temp = element.dictLabel
        }
      })
      return temp
    },
    goToSignInDetail (flag) {
      var numPool = []
      for (let index = 0; index < this.dataList.length; index++) {
        numPool.push(index + 1 + '')
      }
      // var signInData = JSON.parse(JSON.stringify(this.dataList))
      if (flag === 'continueSignIn') { // 继续签到
        for (let index = 0; index < this.dataList.length; index++) {
          const element = this.dataList[index]
          if (element.competitionNumber !== '') {
            numPool.splice(numPool.indexOf(element.competitionNumber), 1)
            // signInData.splice(index, 1)
          }
        }
      }
      // 保存号码池
      window.localStorage.setItem(this.queryForm.raceSchedule + this.queryForm.districtId + this.queryForm.level + 'numPool', JSON.stringify(numPool))
      // 保存签到数据
      window.localStorage.setItem(this.queryForm.raceSchedule + this.queryForm.districtId + this.queryForm.level + 'signInData', JSON.stringify(this.dataList))
      const { href } = this.$router.resolve({
        path: '/individual_racing_schdules/beginSignInDetail',
        query: {
          queryForm: JSON.stringify(this.queryForm)
        }
      })
      window.open(href, '_blank')
      this.continueDialogVisible = false
      this.dialogVisible = true
    },
    showQr (url) {
      this.qrImgUrl = url
      this.showQrDialogVisible = true
    },
    showEditDialog (data) {
      if (this.signInEnable) {
        return this.$message.warning('比赛已开始，不可修改签到')
      }
      this.uploadMatchNumData = JSON.parse(JSON.stringify(data))
      this.editDialogVisible = true
    },
    signInChange (value) {
      console.log('value', value)
      this.uploadMatchNumData = value
      this.uploadMatchNumData.signIn = !!this.uploadMatchNumData.signIn
      this.editSignInData()
    },
    editSignInData () {
      if (this.uploadMatchNumData.signIn === true && this.uploadMatchNumData.competitionNumber < 1) {
        return this.$message.warning('请填写正确比赛号码')
      }
      console.log('this.uploadMatchNumData', this.uploadMatchNumData)
      citySignIn(this.uploadMatchNumData).then((res) => {
        this.$message.success('修改成功')
        this.editDialogVisible = false
        this.getDataList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    checkNotUploadMatchNumData () {
      if (window.localStorage.getItem(this.queryForm.raceSchedule + this.queryForm.districtId + this.queryForm.level + 'notUploadMatchNumData') !== null) {
        this.notUploadMatchNumData = JSON.parse(window.localStorage.getItem(this.queryForm.raceSchedule + this.queryForm.districtId + this.queryForm.level + 'notUploadMatchNumData'))
      }
    },
    async uploadSignInData () {
      const { data: res } = await this.$http.post('/erp/helper/uploadScoreData', this.notUploadMatchNumData)
      console.log('uploadSignInData', res)
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.$message.success('上传成功')
      this.getDataList()
      window.localStorage.removeItem(this.queryForm.raceSchedule + this.queryForm.districtId + this.queryForm.level + 'notUploadMatchNumData')
      this.notUploadMatchNumData = ''
    },
    checkSignInEnable () {
      checkSetPromotionNum(this.queryForm).then((res) => {
        if (res.code !== 200) {
          return this.$message.error(res.message)
        }
        this.signInEnable = res.data
        if (res.data === true) {
          return this.$message.warning('比赛已开始，不可修改签到')
        }
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 修改年级点击事件
    changeGradeDig (rowData) {
      var selectData = JSON.parse(JSON.stringify(rowData))
      this.changeGradeForm.id = selectData.id
      this.changeGradeForm.userName = selectData.userName
      this.changeGradeForm.grade = selectData.grade
      this.changeGradeForm.enrollmentLevel = selectData.enrollmentLevel
      this.changeGradeForm.createcompetitionNumberType = true
      this.showChangeGradeDialogVisible = true
    },
    editGradeScore () {
      updateLevel(this.changeGradeForm).then((res) => {
        this.showChangeGradeDialogVisible = false
        if (res.code === 3094) {
          this.$confirm(res.msg, '提示', {
            confirmButtonText: '确定修改',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            this.changeGradeForm.createcompetitionNumberType = false
            this.editGradeScore()
          }).catch(() => { })
        } else {
          // if (this.changeGradeForm.createcompetitionNumberType === true) {
          //   this.$alert('您在修改后组别的号码牌是' + res.data, '提示', {
          //     confirmButtonText: '确定',
          //     callback: action => {
          this.getDataList()
          //   }
          // })
          // }
        }
      }).catch((err) => {
        console.log('err', err)
      })
    },
    showChangeGradeDialogClose () {
      this.$refs.changeGradeFormRef.resetFields()
    },
    gradeChangeClicked (val) {
      console.log('val', val)
      const gradeData = this.$userInfo.getUserGroupWithGrade(val)
      this.changeGradeForm.grade = val
      this.changeGradeForm.enrollmentLevel = gradeData.groupId
    },
    genderFormatter (data) {
      var temp = '未设置'
      for (const key in this.e_sex) {
        if (Object.hasOwnProperty.call(this.e_sex, key)) {
          const element = this.e_sex[key]
          if (element.dictValue + '' === data.sex + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    }
  }
}
</script>

<style>
.title {
    font-size: 20px;
    text-align: center;
}

.div_algin_right {
    text-align: right;
}
</style>
